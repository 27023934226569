@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7b7b7b;
  border: 2px solid transparent;
}
/* Add this CSS to your global styles or specific CSS file */
.ck-editor__editable_inline {
  height: 300px;
  overflow: auto;
}
.ck-editor__editable h1 {
  font-size: 2em;
  font-weight: bold;
}
.ck-editor__editable h2 {
  font-size: 1.5em;
  font-weight: bold;
}
.ck-editor__editable h3 {
  font-size: 1.17em;
  font-weight: bold;
}
h1 {
  @apply text-4xl font-bold; /* Tailwind CSS classes for H1 */
}

h2 {
  @apply text-3xl font-semibold; /* Tailwind CSS classes for H2 */
}

h3 {
  @apply text-2xl font-medium; /* Tailwind CSS classes for H3 */
}

ul {
  @apply list-disc pl-5; /* Tailwind CSS classes for unordered list */
}

ol {
  @apply list-decimal pl-5; /* Tailwind CSS classes for ordered list */
}
