.dark .dataTable-sorter::after {
  border-bottom-color: #fff;
}

.dark .dataTable-wrapper .dataTable-top .dataTable-search input {
  color: #fff;
}

.dark .dataTable-sorter::before {
  border-top-color: #fff;
}

.dark .dataTable-search .dataTable-input {
  background-color: #111322;
}

.dark .dataTable-selector {
  background-color: #111322;
  color: #fff;
}

.dataTable-top label {
  font-size: 0.75rem;
}

.table tbody tr:last-child td {
  border-width: 0;
}

.table td,
.table th {
  white-space: nowrap;
}

/*!
Simple-DataTables
Github: https://github.com/fiduswriter/Simple-DataTables
*/
.dataTable-wrapper.no-header .dataTable-container {
  border-top: 1px solid #d9d9d9;
}

.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: 1px solid #d9d9d9;
}

.dataTable-top,
.dataTable-bottom {
  padding: 8px 10px;
}

.dataTable-top > nav:first-child,
.dataTable-top > div:first-child,
.dataTable-bottom > nav:first-child,
.dataTable-bottom > div:first-child {
  float: left;
}

.dataTable-top > nav:last-child,
.dataTable-top > div:last-child,
.dataTable-bottom > nav:last-child,
.dataTable-bottom > div:last-child {
  float: right;
}

.dataTable-selector {
  padding: 6px;
}

.dataTable-input {
  padding: 6px 12px;
}

.dataTable-info {
  margin: 7px 0;
}

/* PAGER */
.dataTable-pagination ul {
  margin: 0;
  padding-left: 0;
}

.dataTable-pagination li {
  list-style: none;
  float: left;
}

.dataTable-pagination a {
  border: 1px solid transparent;
  float: left;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  color: #333;
}

.dataTable-pagination a:hover {
  background-color: #d9d9d9;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background-color: #d9d9d9;
  cursor: default;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.dataTable-pagination .pager a {
  font-weight: bold;
}

/* TABLE */
.dataTable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: top;
  padding: 8px 10px;
}

.dataTable-table > thead > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.dataTable-table > tfoot > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-top: 1px solid #d9d9d9;
}

.dataTable-table th {
  vertical-align: bottom;
  text-align: left;
}

.dataTable-table th a {
  text-decoration: none;
  color: inherit;
}

.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

.dataTable-sorter::before {
  border-top: 4px solid #000;
  bottom: 0px;
}

.dataTable-sorter::after {
  border-bottom: 4px solid #000;
  border-top: 4px solid transparent;
  top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}

.dataTables-empty {
  text-align: center;
}

.dataTable-top::after,
.dataTable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

.table > :not(caption) > * > * {
  padding: 0.5rem;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}

/*!
  Simple-DataTables
  Github: https://github.com/fiduswriter/Simple-DataTables
  */
.dataTable-wrapper .dataTable-top {
  padding: 1.5rem;
}

.dataTable-wrapper .dataTable-top .dataTable-dropdown {
  float: left;
}

.dataTable-wrapper .dataTable-top .dataTable-dropdown label {
  margin-bottom: 0;
  margin-left: 0;
  color: #8392ab;
  font-weight: 400;
}

.dataTable-wrapper
  .dataTable-top
  .dataTable-dropdown
  label
  .dataTable-selector {
  border-color: #e9ecef;
  border-radius: 0.25rem;
}

.dataTable-wrapper .dataTable-top .dataTable-search input {
  font-size: 0.875rem;
  color: #495057;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
}

.dataTable-wrapper .dataTable-top .dataTable-search input:focus {
  border-color: #93bce2;
}

.dataTable-wrapper .dataTable-top .dataTable-search input:focus-visible {
  outline: none;
}

.dataTable-wrapper .dataTable-container .table thead tr th {
  padding: 0.75rem 1.5rem;
  opacity: 0.7;
  font-weight: bolder;
  color: #8392ab;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.dataTable-wrapper .dataTable-container .table tbody tr td {
  padding: 0.75rem 1.5rem;
}

.dataTable-wrapper .dataTable-bottom {
  padding: 1.5rem;
  padding-top: 0;
}

.dataTable-wrapper .dataTable-bottom .dataTable-info {
  color: #8392ab;
  font-size: 0.875rem;
}

.dataTable-wrapper
  .dataTable-bottom
  .dataTable-pagination
  .dataTable-pagination-list
  li {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8392ab;
  padding: 0;
  margin: 0 3px;
  border: 1px solid #dee2e6;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
  margin-left: 0;
  cursor: pointer;
}

.dataTable-wrapper
  .dataTable-bottom
  .dataTable-pagination
  .dataTable-pagination-list
  li:hover {
  background: #e9ecef;
}

.dataTable-wrapper
  .dataTable-bottom
  .dataTable-pagination
  .dataTable-pagination-list
  .active {
  background: transparent;
  background-image: linear-gradient(310deg, #5931fb 0%, #00c4ff 100%);
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.09),
    0 2px 3px -1px rgba(0, 0, 0, 0.07);
  color: #fff;
  border: none;
  border-radius: 50% !important;
}

.dataTable-wrapper
  .dataTable-bottom
  .dataTable-pagination
  .dataTable-pagination-list
  .active:hover {
  background-image: linear-gradient(310deg, #5931fb 0%, #00c4ff 100%);
}

.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: 0;
}

.dataTable-container {
  height: 100% !important;
  overflow-x: auto;
}

.dataTable-table thead td,
.dataTable-table thead th,
.dataTable-table tbody td,
.dataTable-table tbody th,
.dataTable-table tfoot td,
.dataTable-table tfoot th {
  vertical-align: middle !important;
}

@media (max-width: 575.98px) {
  .dataTable-top > div:last-child {
    float: left;
    margin-top: 1rem;
  }
}
