@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-clip-text {
  -webkit-background-clip: text;
}

.no_scrollbar::-webkit-scrollbar {
  display: none;
}

label,
p,
span {
  text-transform: capitalize;
}

input[disabled] {
  background-color: #eee !important;
  cursor: not-allowed;
}

select[disabled] {
  background-color: #eee !important;
  cursor: not-allowed;
}

.banner {
  background: url("Assets/banner.jpeg");
  background-size: cover;
  background-position-y: 100%;
}

.banner-profile {
  background-position-y: 82%;
}

.preview-img {
  object-fit: contain;
}

.custom-checkbox::after {
  --tw-content: "\f00c" !important;
  content: var(--tw-content);
}

/* @import 'Assets/css/choices.css' */
/* @import "Assets/css/datatable.css"; @import 'Assets/css/soft-ui-dashboard-tailwind.css' */

.chartContainer {
  overflow: auto !important;
}

.highcharts-yaxis-labels {
  background-color: #fff;
  width: 60px;
  height: 90%;
  /* For WebKit browsers, like Chrome for desktop or Safari on MacOS and iOS Safari */
  font-weight: bold;
}

.highcharts-scrollbar-thumb {
  cursor: pointer;
}

.select__value-container {
  max-height: 69px;
  overflow: auto !important;
}

.select__control--is-focused {
  border-color: #67e8f9 !important;
  box-shadow: 0 0 0 2px #80e2ff !important;
}

::placeholder {
  text-transform: capitalize;
}

::-ms-input-placeholder {
  text-transform: capitalize;
}
@media (min-width: 992px) {
  .lg\:pl-80 {
      padding-left: 17rem !important;
  }
  .w-80 {
    width: 17rem !important;
  }
}