.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it appears above other content */
  }
  
  .overlay.show {
    display: flex; /* Show the overlay when loading is true */
  }
  
  .loader-container {
    position: relative;
    width: 100px; /* Adjust width of loader container as needed */
    height: 100px; /* Adjust height of loader container as needed */
  }
  
  .loader-container .react-loading-skeleton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  